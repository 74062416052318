import React, { useContext, useMemo } from 'react'
import useSearchResults from 'src/templates/parts/useSearchResults'
import { useStaticQuery, graphql } from 'gatsby'

import {
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'


const FilterHandler = ({ products, searchResults }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const graphqlResult = useStaticQuery(graphql`
    query filterHandlerQuery {
      labels: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "sidebar-labels"}}) {
        acfsidebarlabels {
          attr1SidebarLabels
          attr2SidebarLabels
          attr3SidebarLabels
          attr4SidebarLabels
          attr5SidebarLabels
          attr6SidebarLabels
          attr7SidebarLabels
          attr8SidebarLabels
          attr9SidebarLabels
          attr10SidebarLabels
          attr11SidebarLabels
        }
      }
    }
  `)
  const labels = graphqlResult.labels.acfsidebarlabels
  const visibleFilters = Object.values(labels).filter((val) => {
    return val !== 'init'
  })

  // console.log(visibleFilters)


  const [results] = useSearchResults(products, searchResults)

  // const manageProducts = () => {

  //   const tempArrCommon = []
  //   const tempArrType = []
  //   const tempArrPurpose = []
  //   const tempArrCategory = []
  //   // const tempArrCommonCount = []
  //   // const tempArrTypeCount = []
  //   // const tempArrPurposeCount = []
  //   // const tempArrCategoryCount = []

  //   if(results.nodes.length > 0) {
  //     results.nodes.forEach((it) => {

  //       if(it.cPTTXattributes && it.cPTTXattributes.nodes.length > 0) {
  //         for(let i = 0; it.cPTTXattributes.nodes.length > i; i++) {
  //           const at = it.cPTTXattributes.nodes[i]

  //           //  MANAGE TYPE FILTERS (type)
  //           if( at.productattracf.section === 'type' ) {              
  //             const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
  //             if( tempArrType.some(item => at.name === item.name) ) {
  //               const idx = tempArrType.findIndex(item => at.name === item.name)
  //               tempArrType[idx].count ++
  //               // tempArrTypeCount[id].count ++
  //             }
  //             else {
  //               if(at.wpParent !== null) {


  //                 tempArrType.push({
  //                   name: at.name, 
  //                   id: id,
  //                   parentName: at.wpParent.node.name,
  //                   parent: at.wpParent.node.slug,
  //                   section: at.productattracf.section,      
  //                   count: 1,
  //                 })

  //                 // tempArrTypeCount[id] = {
  //                 //   name: at.name, 
  //                 //   id: id,
  //                 //   count: 1
  //                 // }

  //               }
  //             }
  //           }
  //           //  MANAGE PURPOSE FILTERS (purpose)
  //           else if( at.productattracf.section === 'purpose' ) {              
  //             const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
  //             if( tempArrPurpose.some(item => at.name === item.name) ) {
  //               const idx = tempArrPurpose.findIndex(item => at.name === item.name)
  //               tempArrPurpose[idx].count ++
  //               // tempArrPurposeCount[id].count ++
  //             }
  //             else {
  //               if(at.wpParent !== null) {


  //                 tempArrPurpose.push({
  //                   name: at.name, 
  //                   id: id,
  //                   parentName: at.wpParent.node.name,
  //                   parent: at.wpParent.node.slug,
  //                   section: at.productattracf.section,       
  //                   count: 1,
  //                 })

  //                 // tempArrPurposeCount[id] = {
  //                 //   name: at.name, 
  //                 //   id: id,
  //                 //   count: 1
  //                 // }

  //               }
  //             }
  //           }
  //           //  MANAGE CATEGORY FILTERS (category)
  //           else if( at.productattracf.section === 'category' ) {      
  //             const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
  //             if( tempArrCategory.some(item => at.name === item.name) ) {
  //               const idx = tempArrCategory.findIndex(item => at.name === item.name)
  //               tempArrCategory[idx].count ++
  //               // tempArrCategoryCount[id].count ++
  //             }
  //             else {
  //               if(at.wpParent !== null) {

  //                 tempArrCategory.push({
  //                   name: at.name, 
  //                   id: id,
  //                   parentName: at.wpParent.node.name,
  //                   parent: at.wpParent.node.slug,
  //                   section: at.productattracf.section,      
  //                   count: 1,
  //                 })

  //                 // tempArrCategoryCount[id] = {
  //                 //   name: at.name, 
  //                 //   id: id,
  //                 //   count: 1
  //                 // }

  //               }
  //             }
  //           }

  //           //  MANAGE COMMON FILTERS (material, shape, color, quality, azure, puzzle, sides, elements)
  //           else {
  //             const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
  //             if( tempArrCommon.some(item => at.name === item.name) ) {
  //               const idx = tempArrCommon.findIndex(item => at.name === item.name)
  //               tempArrCommon[idx].count ++
  //               // tempArrCommonCount[id].count ++
  //             }
  //             else {
  //               // if(at.wpParent !== null) {
  //               if(at.wpParent !== null && at.wpParent.node !== null) {

  //                 tempArrCommon.push({
  //                   name: at.name, 
  //                   id: id,
  //                   parentName: at.wpParent.node.name,
  //                   parent: at.wpParent.node.slug,
  //                   section: at.productattracf.section,
  //                   count: 1,
  //                 })

  //                 // tempArrCommonCount[id] = {
  //                 //   name: at.name,
  //                 //   id: id,
  //                 //   count: 1
  //                 // }

  //               }
  //             }
  //           }


  //         }
  //       }
        
  //     })
  //   }


  //   dispatch( { type: 'CAT_FILTERS', value: { 
  //     common: [...tempArrCommon],
  //     type: [...tempArrType],
  //     purpose: [...tempArrPurpose],
  //     category: [...tempArrCategory],
  //   } } )

  //   // dispatch( { type: 'COUNT_BY_FILTERS', value: { 
  //   //   null: [...tempArrCommonCount],
  //   //   type: [...tempArrTypeCount],
  //   //   purpose: [...tempArrPurposeCount],
  //   //   category: [...tempArrCategoryCount],
  //   // } } )

  // }
 
  // console.log(results.nodes)

  useMemo(() => {

    // manageProducts()



    const tempArrCommon = []
    const tempArrType = []
    const tempArrPurpose = []
    const tempArrCategory = []
    // const tempArrCommonCount = []
    // const tempArrTypeCount = []
    // const tempArrPurposeCount = []
    // const tempArrCategoryCount = []

    if(results.nodes.length > 0) {
      results.nodes.forEach((it) => {

        if(it.cPTTXattributes && it.cPTTXattributes.nodes.length > 0) {
          for(let i = 0; it.cPTTXattributes.nodes.length > i; i++) {
            const at = it.cPTTXattributes.nodes[i]

            //  MANAGE TYPE FILTERS (type)
            if( at.productattracf.section === 'type' ) {              
              const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
              if( tempArrType.some(item => at.name === item.name) ) {
                const idx = tempArrType.findIndex(item => at.name === item.name)
                tempArrType[idx].count ++
                // tempArrTypeCount[id].count ++
              }
              else {
                if(at.wpParent !== null) {


                  tempArrType.push({
                    name: at.name, 
                    id: id,
                    parentName: at.wpParent.node.name,
                    parent: at.wpParent.node.slug,
                    section: at.productattracf.section,      
                    count: 1,
                  })

                  // tempArrTypeCount[id] = {
                  //   name: at.name, 
                  //   id: id,
                  //   count: 1
                  // }

                }
              }
            }
            //  MANAGE PURPOSE FILTERS (purpose)
            else if( at.productattracf.section === 'purpose' ) {              
              const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
              if( tempArrPurpose.some(item => at.name === item.name) ) {
                const idx = tempArrPurpose.findIndex(item => at.name === item.name)
                tempArrPurpose[idx].count ++
                // tempArrPurposeCount[id].count ++
              }
              else {
                if(at.wpParent !== null) {


                  tempArrPurpose.push({
                    name: at.name, 
                    id: id,
                    parentName: at.wpParent.node.name,
                    parent: at.wpParent.node.slug,
                    section: at.productattracf.section,       
                    count: 1,
                  })

                  // tempArrPurposeCount[id] = {
                  //   name: at.name, 
                  //   id: id,
                  //   count: 1
                  // }

                }
              }
            }
            //  MANAGE CATEGORY FILTERS (category)
            else if( at.productattracf.section === 'category' ) {      
              const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
              if( tempArrCategory.some(item => at.name === item.name) ) {
                const idx = tempArrCategory.findIndex(item => at.name === item.name)
                tempArrCategory[idx].count ++
                // tempArrCategoryCount[id].count ++
              }
              else {
                if(at.wpParent !== null) {

                  tempArrCategory.push({
                    name: at.name, 
                    id: id,
                    parentName: at.wpParent.node.name,
                    parent: at.wpParent.node.slug,
                    section: at.productattracf.section,      
                    count: 1,
                  })

                  // tempArrCategoryCount[id] = {
                  //   name: at.name, 
                  //   id: id,
                  //   count: 1
                  // }

                }
              }
            }

            //  MANAGE COMMON FILTERS (material, shape, color, quality, azure, puzzle, sides, elements)
            else {

              if( visibleFilters.includes(at.wpParent.node.slug) ) {

                const id = at.name.toLowerCase().replace(/[^a-z ]/g, "").replace(/\s/g, "")
                if( tempArrCommon.some(item => at.name === item.name) ) {
                  const idx = tempArrCommon.findIndex(item => at.name === item.name)
                  tempArrCommon[idx].count ++
                  // tempArrCommonCount[id].count ++
                }
                else {
                  // if(at.wpParent !== null) {
                  if(at.wpParent !== null && at.wpParent.node !== null) {
  
                    tempArrCommon.push({
                      name: at.name, 
                      id: id,
                      parentName: at.wpParent.node.name,
                      parent: at.wpParent.node.slug,
                      section: at.productattracf.section,
                      count: 1,
                    })
  
                    // tempArrCommonCount[id] = {
                    //   name: at.name,
                    //   id: id,
                    //   count: 1
                    // }
  
                  }
                }

              }


            }


          }
        }
        
      })
    }

    dispatch( { type: 'CAT_FILTERS', value: { 
      common: [...tempArrCommon],
      type: [...tempArrType],
      purpose: [...tempArrPurpose],
      category: [...tempArrCategory],
    } } )

    // dispatch( { type: 'COUNT_BY_FILTERS', value: { 
    //   null: [...tempArrCommonCount],
    //   type: [...tempArrTypeCount],
    //   purpose: [...tempArrPurposeCount],
    //   category: [...tempArrCategoryCount],
    // } } )


  // }, [productsArr])    //    ?????
  // }, [products.nodes, manageProducts])  //    ?????
  // }, [results, manageProducts])  //    ?????
  // }, [manageProducts])  //    ?????
  }, [results.nodes])  //    ?????

  
  return (
    <></>
  )
}
export default FilterHandler