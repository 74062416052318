import { useState, useMemo } from 'react'
const useSearchResults = (products, searchResults) => {
  const resultsInit = () => {
    return products = (searchResults && searchResults.length > 0) ? { nodes: products.nodes.filter((it) => {
      return searchResults.includes(it.title)
    }) }
    : products    
  }
  const [results, setResults] = useState(() => resultsInit())  
  useMemo(() => {
    if(searchResults && searchResults.length > 0) {
      const temp = products.nodes.filter((it) => {
        return searchResults.includes(it.title)
      })
      setResults( { nodes: temp } )
    }
  }, [searchResults, products])
  return [results]
}
export default useSearchResults